
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table, TableRowsSelect } from "@/utils/decorator";
import StandardFlag from "@/components/scope/standard-flag.vue";
import SwitchStatus from "@/components/scope/switch-status.vue";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";
const base = namespace("base");
const marketing = namespace("marketing");

@Component({
  methods: { dayjs },
  components: { SwitchStatus, StandardFlag }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("marketing.please-select-card-template"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class TestingItemList extends Vue {
  @marketing.Action queryCardTemplateList;
  @marketing.Action updateCardTemplateStatus;
  @base.Action getDictionaryList;
  searchForm = {
    cardTemplateName: "",
    cardType: "",
    enableStatus: ""
  };
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  cardTemplateList: any[] = [];
  selectableCheckbox(row, index) {
    console.log("[ row.sourc ]", row.source);
    return row.source !== "1";
  }
  get searchList() {
    return [
      {
        label: "卡名称",
        type: "input",
        value: "",
        prop: "cardTemplateName"
      },
      {
        label: "卡类型",
        type: "select",
        value: "",
        selectList: this.cardTemplateTypeList,
        prop: "cardType"
      },
      {
        label: "启用状态",
        type: "select",
        value: "",
        selectList: [
          {
            label: "全部",
            value: ""
          },
          {
            label: "启用",
            value: "1"
          },
          {
            label: "禁用",
            value: "0"
          }
        ],
        prop: "enableStatus"
      }
    ];
  }
  cardTemplateTypeList: any = [];
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  @TableRowsSelect.updateMethod
  confirmUpdateStatus(status, selectedRow) {
    return this.updateCardTemplateStatus({
      enableStatus: status,
      cardTemplateCodeList: selectedRow
        ? [selectedRow.cardTemplateCode]
        : this.mix_selectedRows.map(item => item.cardTemplateCode)
    }).then(data => {
      this.$message.success(
        status ? (t("setting.enable-successful") as string) : (t("setting.disable-success") as string)
      );
      this.loadListData();
    });
  }
  created() {
    this.init();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryCardTemplateList({
      ...this.searchForm,
      orderItems: this.mix_sortParams,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize,
      status: "9"
    }).then(data => {
      this.cardTemplateList = data.data.list;
      return data;
    });
  }
  async init() {
    this.loadListData();
    this.getDictionaryList("card_type").then(data => {
      this.cardTemplateTypeList = data;
    });
  }
  handleEdit(row) {
    this.$router.push(`/marketing/card-template-edit/${row.cardTemplateCode}`);
  }
  handleView(row) {
    this.$router.push(`/marketing/card-template-view/${row.cardTemplateCode}`);
  }
  handleCommand(command) {
    switch (command) {
      case "group":
        this.$router.push("/marketing/card-template-add");
        break;
      case "valueCard":
        console.log("[ command]", command);
        this.$router.push("/marketing/value-card-add");
        break;
      default:
        console.log("[ command]", command);
    }
  }
  // ////
}
